import axios from 'axios';

import { AUTH } from 'src/lib/firebase';
import { CONFIG } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: CONFIG.site.serverUrl });

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response) {
      const { status } = error.response;

      switch (status) {
        case 401:
          try {
            const { currentUser } = AUTH;
            if (currentUser) {
              if (error.config._retry) {
                window.location.reload();
                return Promise.reject(new Error('Token refresh failed'));
              }
              error.config._retry = true;
              await currentUser.getIdToken(true);
              const newIdToken = await currentUser.getIdToken();
              axiosInstance.defaults.headers.common['x-api-key'] = `${newIdToken}`;
              return axiosInstance(error.config);
            }
            window.location.reload();
            return Promise.reject(new Error('User not authenticated'));
          } catch (refreshError) {
            console.error('Error refreshing token:', refreshError);
            window.location.reload();
            return Promise.reject(new Error('Failed to refresh authentication token'));
          }

        case 403:
          return Promise.reject(new Error('You do not have permission to access this resource'));

        case 404:
          return Promise.reject(new Error('The requested resource was not found'));

        case 405:
          return Promise.reject(new Error('This operation is not allowed'));

        case 429:
          return Promise.reject(new Error('Too many requests. Please try again later'));

        case 500:
          return Promise.reject(new Error('Internal server error. Please try again later'));

        default:
          return Promise.reject(error.response.data?.message || new Error('Something went wrong!'));
      }
    }

    return Promise.reject(new Error('Network error. Please check your connection'));
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  try {
    const [url, config] = Array.isArray(args) ? args : [args];

    const res = await axiosInstance.get(url, { ...config });

    return res.data;
  } catch (error) {
    console.error('Failed to fetch:', error);

    throw error;
  }
};

export const postMethod = async (url, requestBody) => {
  try {
    const res = await axiosInstance.post(url, requestBody);

    return res.data;
  } catch (error) {
    console.error('Failed to fetch:', error);

    throw error;
  }
};
// ----------------------------------------------------------------------

export const endpoints = {
  user: '/users',
  polices: {
    policesList: '/policies',
  },
  alerts: {
    root: 'alerts',
    star: 'star',
  },
  rules: {
    root: '/rules',
  },
  slack: {
    root: '/slack',
    install: '/slack/install',
  },
};
