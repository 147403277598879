import { paths } from 'src/routes/paths';

import packageJson from '../package.json';

// ----------------------------------------------------------------------

export const CONFIG = {
  site: {
    name: 'Zango Ai',
    serverUrl: process.env.REACT_APP_SERVER_URL || 'https://api.dev.zango.ai',
    assetURL: process.env.REACT_APP_ASSET_URL || '',
    basePath: process.env.REACT_APP_BASE_PATH || '',
    imageBasePath: process.env.REACT_APP_IMAGE_BASE_PATH || '',
    version: packageJson.version,
    slackInstallUrl: process.env.REACT_APP_SLACK_INSTALL_URL || 'https://slack.dev.zango.ai',
  },
  // isStaticExport: JSON.parse(`${process.env.BUILD_STATIC_EXPORT}`),
  /**
   * Auth
   * @method firebase  | amplify | jwt | supabase | auth0
   */
  auth: {
    method: 'firebase',
    skip: false,
    redirectPath: paths.dashboard.alerts,
  },
  /**
   * Mapbox
   */
  // mapbox: {
  //   apiKey: process.env.NEXT_PUBLIC_MAPBOX_API_KEY ?? '',
  // },
  /**
   * Firebase
   */
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
  },

  featureToggle: {
    onBoarding: {
      root: process.env.REACT_APP_FEATURE_ONBOARDING_ROOT === 'true',
    },
    dashboard: {
      root: process.env.REACT_APP_FEATURE_DASHBOARD_ROOT === 'true',
    },
    askZango: {
      root: process.env.REACT_APP_FEATURE_ASK_ZANGO_ROOT === 'true',
    },
    alert: {
      root: process.env.REACT_APP_FEATURE_ALERT_ROOT === 'true',
      filter: {
        topics: process.env.REACT_APP_FEATURE_ALERT_FILTER_TOPICS === 'true',
        agencyUpdates: process.env.REACT_APP_FEATURE_ALERT_FILTER_AGENCY_UPDATES === 'true',
        regulationUpdates: process.env.REACT_APP_FEATURE_ALERT_FILTER_REGULATION_UPDATES === 'true',
      },
      toolbar: {
        askZango: process.env.REACT_APP_FEATURE_ALERT_TOOLBAR_ASK_ZANGO === 'true',
        notification: process.env.REACT_APP_FEATURE_ALERT_TOOLBAR_NOTIFICATION === 'true',
        dots: process.env.REACT_APP_FEATURE_ALERT_TOOLBAR_DOTS === 'true',
      },
      tableData: {
        dots: process.env.REACT_APP_FEATURE_ALERT_TABLE_DATA_DOTS === 'true',
      },
      summary: {
        root: process.env.REACT_APP_FEATURE_ALERT_SUMMARY_ROOT === 'true',
        toolbar: {
          askZango: process.env.REACT_APP_FEATURE_ALERT_TOOLBAR_ASK_ZANGO === 'true',
          impactAssesment: process.env.REACT_APP_FEATURE_IMPACT_ASSESMENT_ROOT === 'true',
          dots: process.env.REACT_APP_FEATURE_ALERT_TOOLBAR_DOTS === 'true',
        },
      },
    },
    gapAnalysis: {
      root: process.env.REACT_APP_FEATURE_GAP_ANALYSIS_ROOT === 'true',
    },
    lawLibrary: {
      root: process.env.REACT_APP_FEATURE_LAW_LIBRARY_ROOT === 'true',
    },
    internalPolicy: {
      root: process.env.REACT_APP_FEATURE_INTERNAL_POLICY_ROOT === 'true',
    },
    risk: {
      root: process.env.REACT_APP_FEATURE_RISK_ROOT === 'true',
    },
    control: {
      root: process.env.REACT_APP_FEATURE_CONTROL_ROOT === 'true',
    },
    task: {
      root: process.env.REACT_APP_FEATURE_TASK_ROOT === 'true',
    },
    impactAssesment: {
      root: process.env.REACT_APP_FEATURE_IMPACT_ASSESMENT_ROOT === 'true',
    },
    comments: {
      root: process.env.REACT_APP_FEATURE_COMMENTS_ROOT === 'true',
    },
  },
};
