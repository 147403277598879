import { useCallback } from 'react';

import Button from '@mui/material/Button';

import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';
import { signOut } from 'src/auth/context/firebase/action';
import { Iconify } from 'src/components/iconify';
import { NavItem } from 'src/components/nav-section/vertical/nav-item';
import { Stack } from '@mui/material';

// ----------------------------------------------------------------------

export function SignOutButton({ ...other }) {
  const router = useRouter();

  const { checkUserSession } = useAuthContext();

  const handleLogout = useCallback(async () => {
    try {
      await signOut();
      await checkUserSession?.();

      // onClose?.();
      router.refresh();
    } catch (error) {
      console.error(error);
    }
  }, [checkUserSession, router]);

  return (
    <Stack>
      <Button
        variant="text"
        color="inherit"
        onClick={handleLogout}
        startIcon={<Iconify color="#919EAB" icon="hugeicons:logout-04" width={24} />}
        sx={{
          justifyContent: 'flex-start',
          padding: '4px 8px 4px 12px',
          color: '#919EAB',
          height: '44px',
          '& .MuiButton-startIcon': {
            marginRight: 1,
          },
        }}
        {...other}
      >
        Logout
      </Button>
    </Stack>
  );
}
