import React from 'react';

import { Box, Button, Container, Typography } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Update state with error details
    this.setState({
      error,
      errorInfo,
    });
    console.error('Uncaught error:', error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Container maxWidth="sm">
          <Box sx={{ textAlign: 'center', mt: 8 }}>
            <Typography variant="h4" gutterBottom>
              Oops! Something went wrong.
            </Typography>
            <Typography variant="body1" paragraph>
              We&apos;re sorry, but an error occurred while processing your request.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                window.location.href = '/';
              }}
            >
              Reload Page
            </Button>
            {/* {process.env.NODE_ENV === 'development' && error && (
              <Box sx={{ mt: 4, textAlign: 'left' }}>
                <Typography variant="h6">Error Details:</Typography>
                <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  {error.toString()}
                </pre>
                <Typography variant="h6">Component Stack:</Typography>
                <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  {errorInfo?.componentStack}
                </pre>
              </Box>
            )} */}
          </Box>
        </Container>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
