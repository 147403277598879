import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { CONFIG } from 'src/config-global';
import { DashboardLayout } from 'src/layouts/dashboard';

import { AlertShimmerEffect, LoadingScreen } from 'src/components/loading-screen';

import { AuthGuard } from 'src/auth/guard';
import { paths } from '../paths';
import { UserListProvider } from 'src/context/user-list-provider';
import AlertSummaryShimmerEffect from 'src/components/loading-screen/alert-summary-shimmer-effect';

// ----------------------------------------------------------------------

const DashboardView = lazy(() => import('src/pages/dashboard/dashboard-page'));
const AlertPage = lazy(() => import('src/pages/dashboard/alert-page'));
const AlertDetailsPage = lazy(() => import('src/pages/dashboard/alert-details-page'));
const AskZangoPage = lazy(() => import('src/pages/dashboard/ask-zango-page'));
const ControlPage = lazy(() => import('src/pages/dashboard/control-page'));
const GapAnalysisPage = lazy(() => import('src/pages/dashboard/gap-analysis-page'));
const InternalPoliciesPage = lazy(() => import('src/pages/dashboard/internal-policies-page'));
const ImpactAssesmentPage = lazy(() => import('src/pages/dashboard/impact-assesment-page'));
const LawLibraryPage = lazy(() => import('src/pages/dashboard/law-library-page'));
const RiskPage = lazy(() => import('src/pages/dashboard/risk-page'));
const TaskPage = lazy(() => import('src/pages/dashboard/task-page'));

// ----------------------------------------------------------------------

const layoutContent = (
  <DashboardLayout>
    <UserListProvider>
      <Suspense
        fallback={
          <Outlet
            context={{
              '/dashboard/alerts': <AlertShimmerEffect />,
              '/dashboard/alerts/:id': <AlertSummaryShimmerEffect />,
              '*': <LoadingScreen />,
            }}
          />
        }
      >
        <Outlet />
      </Suspense>
    </UserListProvider>
  </DashboardLayout>
);

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: <AuthGuard>{layoutContent}</AuthGuard>,
    children: [
      { element: <DashboardView />, index: true },
      // { path: paths.dashboard.root, element: <DashboardView /> },
      { path: paths.dashboard.alerts, element: <AlertPage /> },
      { path: `${paths.dashboard.alerts}/:id`, element: <AlertDetailsPage /> },
      { path: paths.dashboard.askZango, element: <AskZangoPage /> },
      { path: paths.dashboard.control, element: <ControlPage /> },
      { path: paths.dashboard.gapAnalysis, element: <GapAnalysisPage /> },
      { path: paths.dashboard.internalPolicies, element: <InternalPoliciesPage /> },
      { path: paths.dashboard.impactAssesment, element: <ImpactAssesmentPage /> },
      { path: paths.dashboard.lawLibrary, element: <LawLibraryPage /> },
      { path: paths.dashboard.risk, element: <RiskPage /> },
      { path: paths.dashboard.task, element: <TaskPage /> },
    ],
  },
];
