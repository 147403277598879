import { Box, Skeleton, Stack } from '@mui/material';

export function AlertShimmerEffect() {
  return (
    <Box sx={{ px: 5, mt: 4 }}>
      {/* Header shimmer */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
        <Skeleton width={200} height={40} />
        <Skeleton width={120} height={40} />
      </Stack>

      {/* Table header shimmer */}
      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Skeleton width={30} height={24} />
        <Skeleton width="15%" height={24} />
        <Skeleton width="25%" height={24} />
        <Skeleton width="20%" height={24} />
        <Skeleton width="20%" height={24} />
        <Skeleton width="10%" height={24} />
      </Stack>

      {/* Table rows shimmer */}
      {[...Array(10)].map((_, index) => (
        <Stack
          key={index}
          direction="row"
          spacing={2}
          sx={{
            py: 2,
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Skeleton width={30} height={24} />
          <Skeleton width="15%" height={24} />
          <Skeleton width="25%" height={24} />
          <Skeleton width="20%" height={24} />
          <Skeleton width="20%" height={24} />
          <Skeleton width="10%" height={24} />
        </Stack>
      ))}

      {/* Pagination shimmer */}
      <Stack direction="row" justifyContent="flex-end" sx={{ mt: 3 }}>
        <Skeleton width={300} height={40} />
      </Stack>
    </Box>
  );
}
