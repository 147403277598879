// ----------------------------------------------------------------------

const ROOTS = {
  AUTH: '/auth',
  DASHBOARD: '/dashboard',
  ONBOARDING: '/on-boarding',
  ALERTS: '/alerts',
  ASKZANGO: '/ask-zango',
  CONTROL: '/control',
  GAPANALYSIS: '/gap-analysis',
  INTERNALPOLICIES: '/internal-policies',
  IMPACTASSESMENT: '/impact-assesment',
  LAWLIBRARY: '/law-library',
  RISK: '/risk',
  TASK: '/tasks',
};

// ----------------------------------------------------------------------

export const paths = {
  // AUTH
  auth: {
    firebase: {
      signIn: `${ROOTS.AUTH}/firebase/sign-in`,
      verify: `${ROOTS.AUTH}/firebase/verify`,
      // signUp: `${ROOTS.AUTH}/firebase/sign-up`,
      resetPassword: `${ROOTS.AUTH}/firebase/reset-password`,
    },
  },
  onBoarding: {
    root: ROOTS.ONBOARDING,
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    alerts: `${ROOTS.DASHBOARD}${ROOTS.ALERTS}`,
    askZango: `${ROOTS.DASHBOARD}${ROOTS.ASKZANGO}`,
    control: `${ROOTS.DASHBOARD}${ROOTS.CONTROL}`,
    gapAnalysis: `${ROOTS.DASHBOARD}${ROOTS.GAPANALYSIS}`,
    internalPolicies: `${ROOTS.DASHBOARD}${ROOTS.INTERNALPOLICIES}`,
    impactAssesment: `${ROOTS.DASHBOARD}${ROOTS.IMPACTASSESMENT}`,
    lawLibrary: `${ROOTS.DASHBOARD}${ROOTS.LAWLIBRARY}`,
    risk: `${ROOTS.DASHBOARD}${ROOTS.RISK}`,
    task: `${ROOTS.DASHBOARD}${ROOTS.TASK}`,
  },
};
