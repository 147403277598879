import { useMemo, useState, useCallback, useEffect, createContext } from 'react';
import { useGetUserList } from 'src/actions/polices';
import { useAuthContext } from 'src/auth/hooks/use-auth-context';

// ----------------------------------------------------------------------

export const UserListContext = createContext(undefined);

export const UserListConsumer = UserListContext.Consumer;

// ----------------------------------------------------------------------

export function UserListProvider({ children }) {
  const { user } = useAuthContext();
  const [userList, setUserList] = useState([]);
  const { data: fetchedUserList, isLoading, error } = useGetUserList();

  useEffect(() => {
    if (user && fetchedUserList) {
      setUserList(fetchedUserList);
    }
  }, [user, fetchedUserList]);

  const addUser = useCallback((newUser) => {
    setUserList((prevList) => [...prevList, newUser]);
  }, []);

  const removeUser = useCallback((userId) => {
    setUserList((prevList) => prevList.filter((user) => user.id !== userId));
  }, []);

  const memoizedValue = useMemo(
    () => ({
      userList,
      addUser,
      removeUser,
      isLoading,
      error,
    }),
    [userList, addUser, removeUser, isLoading, error]
  );

  return <UserListContext.Provider value={memoizedValue}>{children}</UserListContext.Provider>;
}
