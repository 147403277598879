import Box from '@mui/material/Box';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';

// ----------------------------------------------------------------------

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------

export function TableHeadCustom({
  sx,
  order,
  onSort,
  orderBy,
  headLabel,
  rowCount = 0,
  numSelected = 0,
  tableType = 'normal',
  onSelectAllRows,
}) {
  return (
    <TableHead sx={{ ...sx, background: '#fbfbfb' }}>
      <TableRow>
        {onSelectAllRows && (
          <TableCell
            padding="checkbox"
            sx={(theme) => ({
              position: tableType === 'fix' ? 'sticky' : 'static',
              left: tableType === 'fix' ? 0 : 'auto',
              zIndex: tableType === 'fix' ? 100 : 'auto',
              backgroundColor: tableType === 'fix' ? 'white' : theme.palette.background.default,
            })}
          >
            <Checkbox
              indeterminate={!!numSelected && numSelected < rowCount}
              checked={!!rowCount && numSelected === rowCount}
              onChange={(event) => onSelectAllRows(event.target.checked)}
              inputProps={{
                name: 'select-all-rows',
                'aria-label': 'select all rows',
              }}
            />
          </TableCell>
        )}

        {headLabel.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={(theme) => ({
              width: headCell.width,
              whiteSpace: 'nowrap',
              minWidth: headCell.minWidth,
              position:
                (index === 0 || index === headLabel.length - 1) && tableType === 'fix'
                  ? 'sticky'
                  : 'static',
              left: index === 0 && tableType === 'fix' ? 43 : 'auto',
              right: index === headLabel.length - 1 && tableType === 'fix' ? 0 : 'auto',
              zIndex:
                (index === 0 || index === headLabel.length - 1) && tableType === 'fix'
                  ? 100
                  : 'auto',
              backgroundColor:
                index === 0 && tableType === 'fix' ? 'white' : theme.palette.background.default,
            })}
          >
            {onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onSort(headCell.id)}
              >
                {headCell.label}

                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
