import { forwardRef } from 'react';

import Box from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';
// import { useTheme } from '@mui/material/styles';

import { RouterLink } from 'src/routes/components';

import { CONFIG } from 'src/config-global';

import { logoClasses } from './classes';

// ----------------------------------------------------------------------

export const Logo = forwardRef(
  ({ width = 92, height = 32, disableLink = false, className, href = '/', sx, ...other }, ref) => {
    // const theme = useTheme();

    // const gradientId = useId();

    // const PRIMARY_LIGHT = theme.vars.palette.secondary.light;

    // const PRIMARY_LIGHTER = theme.vars.palette.secondary.lighter;
    // const PRIMARY_MAIN = theme.vars.palette.secondary.main;

    // const PRIMARY_DARK = theme.vars.palette.secondary.dark;

    /*
     * OR using local (public folder)
     * const logo = ( <Box alt="logo" component="img" src={`${CONFIG.site.imageBasePath}/logo/logo-single.svg`} width={width} height={height} /> );
     */
    const logo = (
      <Box
        alt="logo"
        component="img"
        src={`${CONFIG.site.imageBasePath}/logo/logo-text.svg`}
        width={width}
        height={height}
      />
    );

    // const logo = (
    //   <svg
    //     width="100%"
    //     height="100%"
    //     viewBox="0 0 40 40"
    //     fill="none"
    //     xmlns="http://www.w3.org/2000/svg"
    //   >
    //     <path
    //       d="M18.0033 35.937H4.19922L21.9709 4.06784H35.867L18.0033 35.937Z"
    //       fill={`url(#${`${gradientId}-1`})`}
    //     />
    //     <path
    //       d="M30.5507 35.9371H20.0332L25.0613 26.8904C26.0792 25.0595 28.0091 23.9241 30.1032 23.9241C34.7994 23.9241 37.7554 28.9832 35.4503 33.0739C34.4537 34.8429 32.5809 35.9371 30.5507 35.9371Z"
    //       fill={`url(#${`${gradientId}-2`})`}
    //     />
    //     <path
    //       d="M9.44883 4.06784H19.9663L14.9381 13.1145C13.9203 14.9454 11.9904 16.0808 9.89625 16.0808C5.20075 16.0808 2.24407 11.0217 4.54921 6.93105C5.54577 5.16199 7.41792 4.06784 9.44883 4.06784Z"
    //       fill={`url(#${`${gradientId}-3`})`}
    //     />
    //     <defs>
    //       <linearGradient
    //         id={`${gradientId}-1`}
    //         x1="4.19922"
    //         y1="4.06784"
    //         x2="36.0677"
    //         y2="35.735"
    //         gradientUnits="userSpaceOnUse"
    //       >
    //         <stop stopColor={PRIMARY_LIGHTER} />
    //         <stop offset="1" stopColor={PRIMARY_LIGHT} />
    //       </linearGradient>
    //       <linearGradient
    //         id={`${gradientId}-2`}
    //         x1="20.0332"
    //         y1="23.9241"
    //         x2="31.525"
    //         y2="39.437"
    //         gradientUnits="userSpaceOnUse"
    //       >
    //         <stop stopColor={PRIMARY_MAIN} />
    //         <stop offset="1" stopColor={PRIMARY_DARK} />
    //       </linearGradient>
    //       <linearGradient
    //         id={`${gradientId}-3`}
    //         x1="3.75"
    //         y1="4.06784"
    //         x2="15.2418"
    //         y2="19.5807"
    //         gradientUnits="userSpaceOnUse"
    //       >
    //         <stop stopColor={PRIMARY_MAIN} />
    //         <stop offset="1" stopColor="#5119B7" />
    //       </linearGradient>
    //     </defs>
    //   </svg>

    // );

    return (
      <NoSsr
        fallback={
          <Box
            width={width}
            height={height}
            className={logoClasses.root.concat(className ? ` ${className}` : '')}
            sx={{
              flexShrink: 0,
              display: 'inline-flex',
              verticalAlign: 'middle',
              ...sx,
            }}
          />
        }
      >
        <Box
          ref={ref}
          component={RouterLink}
          href={href}
          width={width}
          height={height}
          className={logoClasses.root.concat(className ? ` ${className}` : '')}
          aria-label="logo"
          sx={{
            flexShrink: 0,
            display: 'inline-flex',
            verticalAlign: 'middle',
            ...(disableLink && { pointerEvents: 'none' }),
            ...sx,
          }}
          {...other}
        >
          {logo}
        </Box>
      </NoSsr>
    );
  }
);
